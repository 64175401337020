<template>
  <div>
    <h5 v-if="show_title" class="title">
      <strong
        >Mis
        {{
          $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
        }}</strong
      >
    </h5>
    <ul>
      <li v-for="matter in student_matters" :key="matter.id">
        <p>
          <b-icon class="mr-2" icon="journal-bookmark-fill"></b-icon
          >{{ matter.name }} -
          {{ getMatterSection(matter.egress_profile_matter_id) }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MattersStudying",
  props: {
    user: {
      type: Object,
      required: true,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      student_matters: [],
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
    }),
    filteredSections() {
      let filtered_sections = this.sections.filter((x) =>
        this.user.sections_as_student.includes(x.id)
      );
      // console.log(filtered_sections);
      return filtered_sections ? filtered_sections : null;
    },
  },
  methods: {
    fetchMatters() {
      if (!this.user) return;
      this.$restful
        .Post(`/teaching/get_student_matters/?student_id=${this.user.id}`)
        .then((response) => {
          this.student_matters = response;
        });
    },
    getMatterSection(matter_id) {
      // console.log("MATTER_ID", this.matter_id);
      const matter_section = this.filteredSections.find(
        (x) => x.egress_profile_matter == matter_id
      );
      return matter_section ? matter_section.name : "";
    },
  },
  created() {
    this.fetchMatters();
    this.user.sections_as_student.forEach((section_id) => {
      this.$store.dispatch(names.FETCH_SECTION, section_id);
    });
  },
};
</script>

<style scoped>
ul li {
  margin-left: -1px;
  padding-left: 0.6rem;
  margin-bottom: -0.9rem;
  text-align: left;
  transition: 0.3s;
  list-style-type: none;
}
ul li:hover {
  background-color: #fff;
  color: var(--primary-hover-color);
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s;
}
.card .title {
  padding-bottom: 0.2rem;
  margin: 0 1rem;
  margin-bottom: 0.2rem !important;
  border-bottom: 1px solid var(--primary-hover-color);
}
</style>